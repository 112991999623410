<template>
  <div>
    <h2>Offene Redebox</h2>
    <SpeechBox :speakers="$store.state.openSpeakers" />
    <h2>Quotierte Redebox</h2>
    <SpeechBox :speakers="$store.state.quotedSpeakers" />
  </div>
</template>

<script>
import SpeechBox from '@/components/content/SpeechBox.vue'

export default {
  name: 'SpeechBoxes',
  components: {
    SpeechBox
  }
}
</script>
