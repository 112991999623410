<template>
  <div>
    <h2>Aktueller Programmpunkt</h2>
    <template v-if="$store.state.currentPoll">
      <v-card class="mt-4 mb-5 text-h6">
        <v-card-text>
          <v-simple-table>
            <thead>
              <tr>
                <th class="text-subtitle-2">Fragestellung</th>
                <th class="text-subtitle-2">Status</th>
                <th class="text-subtitle-2">Abgegebene Stimmen</th>
                <th class="text-subtitle-2">Typ</th>
                <th class="text-subtitle-2">Abstimmung #</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-body-1">{{ $store.state.currentPoll.question }}</td>
                <td class="text-body-1">{{ $store.state.currentPoll.status }}</td>
                <td class="text-body-1">{{ $store.state.currentPoll.submittedVotes }}</td>
                <td class="text-body-1">{{ $store.state.currentPoll.type }}</td>
                <td class="text-body-1">{{ $store.state.currentPoll.round }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
      </v-card>

      <div v-if="$store.state.currentPoll.options && $store.state.currentPoll.options[0]">
        <h3>
          Stimmen
        </h3>
        <v-card class="mt-4 mb-6">
          <v-card-text>
            <v-simple-table>
              <thead v-if="$store.state.currentPoll.options[0].title">
                <tr>
                  <th
                    class="text-subtitle-2"
                    :colspan="group.options.length"
                    v-for="group in $store.state.currentPoll.options"
                    :key="group.id"
                  >
                    {{ group.title }}
                  </th>
                </tr>
              </thead>
              <thead>
                <tr>
                  <template v-for="group in $store.state.currentPoll.options">
                    <th
                      class="text-subtitle-2"
                      v-for="option in group.options"
                      :key="option.id"
                    >
                      {{ option.title }}
                    </th>
                  </template>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <template v-for="group in $store.state.currentPoll.options">
                    <td
                      class="text-body-1"
                      v-for="option in group.options"
                      :key="option.id"
                    >
                      {{ option.votes }}
                    </td>
                  </template>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </div>
    </template>
    <p v-else>Es gibt keine Informationen zum aktuellen Programmpunkt.</p>
  </div>
</template>

<script>
export default {
  name: 'CurrentPoll'
}
</script>
