<template>
  <v-col :cols="cols">
    <CurrentPollTitle v-if="elements.includes('currentPollTitle')" />
    <Agenda v-if="elements.includes('agenda')" />
    <Delegates v-if="elements.includes('delegates')" />
    <CurrentPoll v-if="elements.includes('currentPoll')" />
    <ComingPolls v-if="elements.includes('comingPolls')" />
    <BylawMotions v-if="elements.includes('bylawMotions')" />
    <CurrentSpeeches v-if="elements.includes('currentSpeeches')" />
    <SpeechBoxes v-if="elements.includes('speechBoxes')" />
    <Questions v-if="elements.includes('questions')" />
    <Candidacies v-if="elements.includes('candidacies')" />
  </v-col>
</template>

<script>

import CurrentPollTitle from '@/components/content/CurrentPollTitle.vue'
import Agenda from '@/components/content/Agenda.vue'
import Delegates from '@/components/content/Delegates.vue'
import CurrentPoll from '@/components/content/CurrentPoll.vue'
import ComingPolls from '@/components/content/ComingPolls.vue'
import BylawMotions from '@/components/content/BylawMotions.vue'
import CurrentSpeeches from '@/components/content/CurrentSpeeches.vue'
import SpeechBoxes from '@/components/content/SpeechBoxes.vue'
import Questions from '@/components/content/Questions.vue'
import Candidacies from '@/components/content/Candidacies.vue'

export default {
  name: 'ViewColumn',
  components: {
    CurrentPollTitle,
    Agenda,
    Delegates,
    CurrentPoll,
    ComingPolls,
    BylawMotions,
    CurrentSpeeches,
    SpeechBoxes,
    Questions,
    Candidacies
  },
  props: {
    elements: Array,
    cols: Number
  }
}
</script>
