<template>
  <div>
    <h2>Stimmkarten</h2>
    <v-card class="mt-4 mb-6" v-if="$store.state.delegates">
      <v-card-text>
        <v-simple-table>
          <thead>
            <tr>
              <th class="text-subtitle-2">Stimmkarten</th>
              <th class="text-subtitle-2">Vergeben</th>
              <th class="text-subtitle-2">männlich</th>
              <th class="text-subtitle-2">weiblich</th>
              <th class="text-subtitle-2">divers</th>
              <th class="text-subtitle-2">Uhrzeit</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="text-body-1">{{ $store.state.delegates.total }}</td>
              <td class="text-body-1">{{ $store.state.delegates.present }}</td>
              <td class="text-body-1">{{ $store.state.delegates.male ? $store.state.delegates.male : 0 }}</td>
              <td class="text-body-1">{{ $store.state.delegates.female ? $store.state.delegates.female : 0 }}</td>
              <td class="text-body-1">{{ $store.state.delegates.diverse ? $store.state.delegates.diverse : 0 }}</td>
              <td class="text-body-1">{{ $store.state.delegates.time }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <p v-else>Es gibt keine Informationen zu den Stimmkarten.</p>
  </div>
</template>

<script>
export default {
  name: 'Delegates'
}
</script>
