<template>
  <div>
    <h2>Aktuelle Redebeiträge</h2>
    <v-card class="mt-4 mb-6" v-if="$store.state.activeSpeakers">
      <v-card-text>
        <v-simple-table>
          <thead>
            <tr>
              <th class="text-subtitle-2">Name</th>
              <th class="text-subtitle-2">Kreisverband</th>
              <th class="text-subtitle-2">Box</th>
              <th class="text-subtitle-2">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in $store.state.activeSpeakers"
              :key="item.id"
            >
              <td class="text-body-1">{{ item.speaker }}</td>
              <td class="text-body-1">{{ item.division }}</td>
              <td class="text-body-1">{{ item.box }}</td>
              <td class="text-body-1">{{ item.status }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <p v-else>Es gibt keine Redebeiträge zu diesem Programmpunkt.</p>
  </div>
</template>

<script>
export default {
  name: 'CurrentSpeeches'
}
</script>
