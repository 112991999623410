<template>
  <div>
    <h2>Tagesordnung</h2>
    <v-expansion-panels
      :accordion="true"
      class="agenda mt-4 mb-6"
      v-if="$store.state.agenda && $store.state.agenda.items && $store.state.agenda.items.length"
      multiple
    >
      <v-expansion-panel
        v-for="item in $store.state.agenda.items"
        :key="item.id"
        :readonly="!item.polls || !item.polls.length"
      >
        <v-expansion-panel-header class="text-body-1">
          <span :class="{ 'font-weight-bold': item.current }">
            <span v-if="$store.state.agenda.showDate" class="agenda-date">{{ item.start }}</span>
            <span>{{ item.title }}</span>
          </span>
          <template v-slot:actions v-if="!item.polls || !item.polls.length">
            <v-icon></v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content v-if="item.polls && item.polls.length">
          <v-simple-table>
            <thead>
              <tr>
                <th>Titel</th>
                <th>Typ</th>
                <th>Status</th>
                <th class="text-center">Fragen erlaubt</th>
                <th class="text-center">Kandidaturen erlaubt</th>
                <th class="text-center">Redebox geöffnet</th>
              </tr>
            </thead>
            <tbody>
            <tr
              v-for="poll in item.polls"
              :key="poll.id"
              :class="{ 'font-weight-bold': poll.isCurrent }"
            >
              <td>
                {{ poll.question }}
              </td>
              <td>
                {{ poll.type }}
              </td>
              <td>
                {{ poll.status }}
              </td>
              <td class="text-center">
                <v-icon color="green" v-if="poll.questionsEnabled">mdi-check</v-icon>
                <v-icon color="red" v-else>mdi-close</v-icon>
              </td>
              <td class="text-center">
                <v-icon color="green" v-if="poll.candidacyEnabled">mdi-check</v-icon>
                <v-icon color="red" v-else>mdi-close</v-icon>
              </td>
              <td class="text-center">
                <v-icon color="green" v-if="poll.speechBoxEnabled">mdi-check</v-icon>
                <v-icon color="red" v-else>mdi-close</v-icon>
              </td>
            </tr>
            </tbody>
          </v-simple-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <p v-else>Es gibt keine Informationen zur Tagesordnung.</p>
  </div>
</template>

<script>
export default {
  name: 'Agenda'
}
</script>

<style lang="scss">
  .agenda-date {
    width: 70px;
    display: inline-block;
  }

  .agenda {
    .v-expansion-panel-content__wrap {
      padding: 0;
    }

    .v-data-table {
      border-radius: 0;
      border-top: 1px solid lightgray;
      background-color: #F8F8F8;
    }

    th {
      font-size: 0.875rem !important;
    }

    td {
      font-size: 1rem !important;
    }
  }
</style>
