<template>
  <div>
    <h2>Kandidaturen</h2>
    <v-card class="mt-4 mb-6" v-if="$store.state.candidacies && $store.state.candidacies.length">
      <v-card-text>
        <v-simple-table>
          <thead>
            <tr>
              <th class="text-subtitle-2">Name</th>
              <th class="text-subtitle-2">Geschlecht</th>
              <th class="text-subtitle-2">KV</th>
              <th class="text-subtitle-2">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in $store.state.candidacies"
              :key="item.id"
            >
              <td class="text-body-1">{{ item.name }}</td>
              <td class="text-body-1">{{ item.gender }}</td>
              <td class="text-body-1">{{ item.division }}</td>
              <td class="text-body-1">{{ item.status }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <p v-else>Für diesen Programmpunkt gibt es keine Kandidaturen.</p>
  </div>
</template>

<script>

export default {
  name: 'Candidacies'
}
</script>
