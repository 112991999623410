<template>
  <div>
    <v-alert
      dense
      type="error"
      v-if="$store.state.exceptionCount >= 3 || $store.state.failedConnectionCounter >= 3"
    >
      Es gibt ein Problem mit der <strong>API</strong>. Bitte wende Dich an <strong>{{ $store.state.support.name }}</strong>
      (<a :href="'mailto:' + $store.state.support.mail + '?subject=' + $store.state.support.mailSubject" >{{ $store.state.support.mail }}</a>)
    </v-alert>
    <v-alert
      dense
      type="warning"
      v-if="$store.state.voteError"
    >
      Es gibt möglichwerweise ein Problem mit den <strong>Abstimmungsergebnissen</strong>. Es haben <strong>{{ $store.state.voteError.voteCount }}</strong>
      Personen abgestimmt, aber die Summe der Stimmen für die einzelnen Optionen ist <strong>{{ $store.state.voteError.sumOptionCount }}</strong>.
    </v-alert>
    <h1 class="mb-4">{{ view.title }}</h1>
    <v-row v-if="$store.state.connectionStatus === 'connected'">
      <ViewColumn :elements="view.elements[0]" :cols="12/view.cols" />
      <ViewColumn :elements="view.elements[1]" :cols="12/view.cols" v-if="view.cols>1" />
      <ViewColumn :elements="view.elements[2]" :cols="12/view.cols" v-if="view.cols>2" />
    </v-row>
    <div v-else-if="$store.state.connectionStatus === 'connecting'" class="pt-4">
      Verbindung wird aufgebaut...
    </div>
    <div v-else class="pt-4 red--text">
      <p>
        Du bist mit keinem Meeting verbunden!
      </p>
      <v-btn
        @click="goToLogin()"
        class="pa-6 mb-6 mr-6"
        color="#19A229"
        dark>
        Verbinden
      </v-btn>
    </div>
  </div>
</template>

<script>

import ViewColumn from '@/components/content/ViewColumn.vue'

export default {
  name: 'Content',
  components: {
    ViewColumn
  },
  props: {
    view: Object
  },
  methods: {
    goToLogin () {
      this.$router.push('/')
    }
  }
}
</script>

<style lang="scss">
.v-alert a {
  color: #ffffff;

  &:hover {
    opacity: 0.8;
  }
}
</style>
