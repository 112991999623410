<template>
  <div>
    <h2>Fragen</h2>
    <p class="text-subtitle-2">
      Insgesamt: {{ total }}, davon {{ totalWomen }} von Frauen<br/>
      Gezogen: {{ pulled }}, davon {{ pulledWomen }} von Frauen
    </p>
    <v-card class="mt-4 mb-6" v-if="questions && pulled">
      <v-card-text>
        <v-simple-table>
          <thead>
            <tr>
              <th class="text-subtitle-2">Frage</th>
              <th class="text-subtitle-2">Von</th>
              <th class="text-subtitle-2">Geschlecht</th>
              <th class="text-subtitle-2">KV</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in questions"
              :key="item.id"
            >
              <td class="text-body-1">{{ item.text }}</td>
              <td class="text-body-1">{{ item.name }}</td>
              <td class="text-body-1">{{ item.gender }}</td>
              <td class="text-body-1">{{ item.division }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>

export default {
  name: 'Questions',
  computed: {
    questions: function () {
      if (this.$store.state.questions) {
        return this.$store.state.questions.questions
      }
      return null
    },
    total: function () {
      if (this.$store.state.questions) {
        return this.$store.state.questions.total
      }
      return 0
    },
    totalWomen: function () {
      if (this.$store.state.questions) {
        return this.$store.state.questions.women
      }
      return 0
    },
    pulled: function () {
      if (this.$store.state.questions && this.$store.state.questions.questions) {
        return this.$store.state.questions.questions.length
      }
      return 0
    },
    pulledWomen: function () {
      if (this.$store.state.questions) {
        return this.$store.state.questions.womenPulled
      }
      return 0
    }
  }
}
</script>
