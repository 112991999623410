<template>
  <div>
    <v-card class="mt-4 mb-6" v-if="speakers">
      <v-card-text>
        <v-simple-table>
          <thead>
            <tr>
              <th class="text-subtitle-2">Name</th>
              <th class="text-subtitle-2">Kreisverband</th>
              <th class="text-subtitle-2">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in speakers"
              :key="item.id"
            >
              <td class="text-body-1">{{ item.speaker }}</td>
              <td class="text-body-1">{{ item.division }}</td>
              <td class="text-body-1">{{ item.status }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <p v-else>Es gibt keine Informationen zu dieser Redebox.</p>
  </div>
</template>

<script>

export default {
  name: 'SpeechBox',
  props: {
    speakers: Array
  }
}
</script>
