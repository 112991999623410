<template>
  <div>
    <h2>GO-Anträge</h2>
    <v-card class="mt-4 mb-6" v-if="$store.state.bylawMotions.length">
      <v-card-text>
        <v-simple-table>
          <thead>
            <tr>
              <th class="text-subtitle-2">Beschreibung</th>
              <th class="text-subtitle-2">Antragsteller:in</th>
              <th class="text-subtitle-2">Kreisverband</th>
              <th class="text-subtitle-2">Status</th>
              <th class="text-subtitle-2">Zeit</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in $store.state.bylawMotions"
              :key="item.id"
            >
              <td class="text-body-1">
                {{ item.description }}
                <span v-if="item.isNew" class="red--text ml-4">
                  <strong>NEU</strong>
                </span>
              </td>
              <td class="text-body-1">{{ item.movant }}</td>
              <td class="text-body-1">{{ item.chapter }}</td>
              <td class="text-body-1">{{ item.status }}</td>
              <td class="text-body-1">{{ item.time }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <p v-else>Es gibt keine Informationen zu GO Anträgen.</p>
  </div>
</template>

<script>
export default {
  name: 'BylawMotions'
}
</script>
