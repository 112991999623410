<template>
  <div>
    <h2>Anstehende Abstimmungen</h2>
    <v-card class="mt-4 mb-6" v-if="$store.state.polls && $store.state.polls.length">
      <v-card-text>
        <v-simple-table>
          <thead>
            <tr>
              <th class="text-subtitle-2">Fragestellung</th>
              <th class="text-subtitle-2">Status</th>
              <th class="text-subtitle-2 text-center">Fragen</th>
              <th class="text-subtitle-2 text-center">Kandidaturen</th>
              <th class="text-subtitle-2 text-center">Redebox</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in $store.state.polls"
              :key="item.id"
            >
              <td class="text-body-1">{{ item.question }}</td>
              <td class="text-body-1">{{ item.status }}</td>
              <td class="text-center">
                <v-icon color="green" v-if="item.questionsEnabled">mdi-check</v-icon>
                <v-icon color="red" v-else>mdi-close</v-icon>
              </td>
              <td class="text-center">
                <v-icon color="green" v-if="item.candidacyEnabled">mdi-check</v-icon>
                <v-icon color="red" v-else>mdi-close</v-icon>
              </td>
              <td class="text-center">
                <v-icon color="green" v-if="item.speechBoxEnabled">mdi-check</v-icon>
                <v-icon color="red" v-else>mdi-close</v-icon>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <p v-else>Es gibt keine anstehenden Abstimmungen.</p>
  </div>
</template>

<script>
export default {
  name: 'ComingPolls'
}
</script>
